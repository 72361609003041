<template>
  <section id="meeting-card">
    <v-card flat>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="margin-top: -18px"
            absolute
            right
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="red"
            class="white--text"
            @click="remove(meeting.meeting_id)"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete Meeting</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="margin-top: -18px"
            absolute
            right
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="info"
            class="mr-11"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Meeting</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="margin-top: -18px; margin-right: 88px"
            absolute
            right
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="warning"
            @click="start(meeting.uuid)"
          >
            <v-icon>mdi-video-outline</v-icon>
          </v-btn>
        </template>
        <span>Start Meeting</span>
      </v-tooltip>

      <v-card-text>
        <div
          class="body-1 mt-5"
          :class="dark == 1 ? 'white--text' : 'black--text'"
        >
          {{ meeting.topic }}
        </div>
        <div class="">
          <span class="caption" v-if="!more">
            {{ meeting.agenda.slice(0, 100) }}
          </span>
          <span class="caption" v-else>
            {{ meeting.agenda }}
          </span>
          <span @click="more = !more" class="caption primary--text">
            Read {{ more ? "short" : "more" }}...
          </span>
        </div>
        <div class="mt-5">
          <small>MEETING PARTICIPANTS</small>
          <div>
            <v-chip
              class="ma-1 caption"
              v-for="(member, i) in meeting.members"
              :key="i"
            >
              {{ $helpers.level(member.member_level) }}
            </v-chip>
          </div>
        </div>
        <div class="d-flex justify-space-between mx-1 mt-5">
          <div class="">
            <small>START AT</small>
            <div class="caption">
              {{ $helpers.dateFormat(meeting.start_time) }}
            </div>
          </div>
          <div class="">
            <small>DURATION</small>
            <div class="caption">{{ meeting.duration }}</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: ["meeting", "dark"],
  data: () => ({
    more: false,
  }),
  methods: {
    ...mapActions('admin', [
      'deleteMeeting'
    ]),

    start(uuid) {
      if (confirm("Are you sure?\nYou want to start this meeting?")) {
        this.$router.push({
          name: "Admin Zoom Session",
          params: { uuid: uuid },
        });
        // let routeData = this.$router.resolve({name: 'Admin Zoom Session', params: { uuid: uuid },});
        // window.open(routeData.href, '_blank');
      }
    },
    remove(id) {
      if (confirm("Are you sure?\nYou want to delete this meeting?")) {
        this.deleteMeeting(id)
      }
    }
  },
};
</script>