<template>
  <section id="meetings">
    <v-row>
      <v-col cols="12" xl="9" lg="10">
        <v-row class="mt-5">
          <v-col cols="12" lg="6" v-for="item in meetings" :key="item.id">
            <Card :meeting="item" :dark="user.dark" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Card from "./Card.vue";
import { mapState } from "vuex";
export default {
  components: {
    Card,
  },
  computed: {
    ...mapState("admin", {
      meetings: (state) => state.meetings,
      user: (state) => state.user,
    }),
  },
};
</script>