var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"meeting-card"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",staticStyle:{"margin-top":"-18px"},attrs:{"absolute":"","right":"","fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.remove(_vm.meeting.meeting_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}])},[_c('span',[_vm._v("Delete Meeting")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-11",staticStyle:{"margin-top":"-18px"},attrs:{"absolute":"","right":"","fab":"","small":"","color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}])},[_c('span',[_vm._v("Edit Meeting")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-top":"-18px","margin-right":"88px"},attrs:{"absolute":"","right":"","fab":"","small":"","color":"warning"},on:{"click":function($event){return _vm.start(_vm.meeting.uuid)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-video-outline")])],1)]}}])},[_c('span',[_vm._v("Start Meeting")])]),_c('v-card-text',[_c('div',{staticClass:"body-1 mt-5",class:_vm.dark == 1 ? 'white--text' : 'black--text'},[_vm._v(" "+_vm._s(_vm.meeting.topic)+" ")]),_c('div',{},[(!_vm.more)?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.meeting.agenda.slice(0, 100))+" ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.meeting.agenda)+" ")]),_c('span',{staticClass:"caption primary--text",on:{"click":function($event){_vm.more = !_vm.more}}},[_vm._v(" Read "+_vm._s(_vm.more ? "short" : "more")+"... ")])]),_c('div',{staticClass:"mt-5"},[_c('small',[_vm._v("MEETING PARTICIPANTS")]),_c('div',_vm._l((_vm.meeting.members),function(member,i){return _c('v-chip',{key:i,staticClass:"ma-1 caption"},[_vm._v(" "+_vm._s(_vm.$helpers.level(member.member_level))+" ")])}),1)]),_c('div',{staticClass:"d-flex justify-space-between mx-1 mt-5"},[_c('div',{},[_c('small',[_vm._v("START AT")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$helpers.dateFormat(_vm.meeting.start_time))+" ")])]),_c('div',{},[_c('small',[_vm._v("DURATION")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.meeting.duration))])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }